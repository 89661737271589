import React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

import OtherSites from '../OtherSites';
import Pin from './images/icon-pin.svg';
import { device } from '../../styles/mixins';

const Container = styled.div`
  margin: 90px 24px;
  padding-top: 21px;
  color: #9b9b9b;
  line-height: 1.4;

  a,
  a:visited {
    color: #435172;
    text-decoration: none;
  }

  .footer-menu {
    display: none;
  }

  @media ${device.tabletLandscapeUp} {
    margin: 90px 140px;

    .footer-menu {
      display: block;
    }
  }
`;

const MadeWithLove = styled.div`
  text-align: center;
  font-size: 12px;
  color: #2a4560;
`;

const Section = styled.div`
  border-top: solid 1px #cbe3fa;
  padding: 20px 0;
`;

const Columns = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  @media ${device.tabletPortraitUp} {
    flex-direction: row;
  }
`;

const Column = styled.div`
  margin-right: 5em;

  @media ${device.tabletPortraitUp} {
    &:last-child {
      flex: 1;
      text-align: right;
      margin: 0;
    }
  }
`;

const Temp = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: space-between;
`;

const Address = styled.div`
  flex: 1;
  p {
    margin-right: 36px;
  }
`;

const PageFooter = props => (
  <Container>
    <Section>
      <Columns>
        <Column>
          <p>
            <Link to="/">361 Studio</Link>
          </p>
          <p>
            Digital Expertise Lab.
            <br />
            Bright and Solid Design.
          </p>
          <p>&copy; Copyright 2019</p>
        </Column>
        <Column className="footer-menu">
          <p>
            <Link to="/">Home</Link>
          </p>
          <p>
            <Link to="/about">About Us</Link>
          </p>
          <p>
            <Link to="/case-studies">Case studies</Link>
          </p>
          <p>
            <Link to="/services">Services</Link>
          </p>
          <p>
            <Link to="/contact">Contact</Link>
          </p>
        </Column>
        <Column>
          <p>
            <Link to="/cookies-policy">Cookies Policy</Link>
          </p>
          <p>
            <Link to="/book-meeting">Book a meeting</Link>
          </p>
        </Column>
        <Column>
          <Temp>
            <Address>
              <img
                src={Pin}
                alt=""
                style={{ float: 'right', marginTop: '-7px' }}
              />
              <p>KVK: 69893209</p>
              <p>VAT: NL559836259B01</p>
            </Address>
            <OtherSites />
          </Temp>
        </Column>
      </Columns>
    </Section>
    <Section>
      <MadeWithLove>
        Made with love from Amsterdam{' '}
        <span aria-label="Blue Heart Emoji" role="img">
          💙
        </span>
      </MadeWithLove>
    </Section>
  </Container>
);

PageFooter.propTypes = {};

PageFooter.defaultProps = {};

export default PageFooter;
