import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
// import { Link } from 'react-router-dom';
import { rgba } from 'polished';
import { device, flexUnit } from '../../styles/mixins';
import Menu from '../Menu';

import DottedLineImage from './images/vertical-dotted-line.svg';

const gradientColor1 = '#2FA1FF';
const gradientColor2 = '#C398FF';

const Wrapper = styled.div`
  // background: linear-gradient(
  //   to right,
  //   ${gradientColor1} 50%,
  //   ${gradientColor2} 50%
  // );
  // background-attachment: fixed;
  // background-repeat: no-repeat;
`;

const Container = styled.div`
  position: relative;
  padding: 40px 24px;

  @media ${device.tabletLandscapeUp} {
    padding: 40px 90px;
  }
`;

const Visual = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url(${props => props.image}) no-repeat center center;
  background-size: cover;
`;

const Content = styled.div`
  position: relative;
  margin: 0 auto;
  max-width: 1440px;

`;

const Gradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;

  background: linear-gradient(
    88deg,
    ${rgba(gradientColor1, 1)} 0%,
    ${rgba(gradientColor1, 1)} 5%,
    ${rgba(gradientColor1, 0.9)} 30%,
    ${rgba(gradientColor2, 0.9)} 80%,
    ${rgba(gradientColor2, 1)} 98%,
    ${rgba(gradientColor2, 1)} 100%
  );
`;

const Title = styled.div`
  ${flexUnit(7, 22, 45, 'vw', 'font-size')};
  font-weight: 600;
  line-height: 1.2;
  color: #ffffff;
  margin-top: 234px;

  @media ${device.phoneOnly} {
    margin-top: 90px;
  }
`;

const SubTitle = styled.div`
  ${flexUnit(4, 12, 20, 'vw', 'font-size')};
  font-weight: 600;
  line-height: 1.8;
  max-width: 40ch; /* break up content based on number of characters */
  color: #ffffff;
  margin-top: 24px;
  margin-bottom: 140px;

  @media ${device.phoneOnly} {
    margin-bottom: 50px;
  }
`;

const Spacing = styled.div`
  text-align: center;
  overflow: hidden;
  margin-bottom: 50px;

  @media ${device.phoneOnly} {
    margin-bottom: 22px;

    img {
      margin-top: -${97 - 30}px;
    }
  }
`;

const PageHeader = ({ title, subtitle, visual, additionalContent, useGradient }) => {
  return (
    <React.Fragment>
      <Menu />
      <Wrapper>
        {/*<Gradient style={{ position: 'fixed', zIndex: 10, height: '130px' }} />*/}
        <Container>
          <Visual image={visual} />
          {useGradient && <Gradient />}
          <Content>
            <Title dangerouslySetInnerHTML={{ __html: title }} />
            <SubTitle>{subtitle}</SubTitle>
            {additionalContent}
          </Content>
        </Container>
      </Wrapper>

      <Spacing>
        <img src={DottedLineImage} alt="" />
      </Spacing>
    </React.Fragment>
  );
};

PageHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  visual: PropTypes.node,
  additionalContent: PropTypes.node,
  useGradient: PropTypes.bool,
};

PageHeader.defaultProps = {
  useGradient: true,
};

export default PageHeader;
