/*
  flexUnit

  Fluid Typography within boundaries
  https://nilsb.org/2019-02-12-sass-mixins-in-styled-components/

  Usage:
    import { flexUnit } from '../../styles/mixins';

    const StyledHeadline = styled.h2`
      h2 {
        ${flexUnit(5, 32, 60, 'vw', 'font-size')}
      }
    `
 */
export const flexUnit = (amount, min, max, unit = 'vw', prop = 'font-size') => {
  const minBreakpoint = (min / amount) * 100;
  const maxBreakpoint = max ? (max / amount) * 100 : false;
  const dimension = unit === 'vw' ? 'width' : 'height';

  return `
    @media (max-${dimension}: ${minBreakpoint}px) {
      ${prop}: ${min}px;
    }

    ${
      max
        ? `
      @media (min-${dimension}: ${maxBreakpoint}px) {
        ${prop}: ${max}px;
      }
    `
        : ''
    }

    ${prop}: ${amount}${unit}
  `;
};

/*
  Media Queries Device Ranges

  Usage is styled components:

    import { device } from '../../styles/mixins';

    @media ${device.desktopUp} {
      max-width: 800px;
    }
*/

export const device = {
  phoneOnly: `(max-width: 599px)`,

  tabletPortaitOnly: `(min-width: 600px) and (max-width: 899px)`,
  tabletPortraitUp: `(min-width: 600px)`,
  tabletPortraitDown: `(max-width: 899px)`,

  tabletLandscapeOnly: `(min-width: 900px) and (max-width: 1199px)`,
  tabletLandscapeUp: `(min-width: 900px)`,
  tabletLandscapeDown: `(max-width: 1199px)`,

  desktopOnly: `(min-width: 1200px) and (max-width: 1799px)`,
  desktopUp: `(min-width: 1200px)`,
  desktopDown: `(max-width: 1799px)`,
};
