import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { device } from '../../../styles/mixins';
import MenuButton from './MenuButton';
import MenuItem from './MenuItem';
import OtherSites from '../../OtherSites';

const Container = styled.div`
  display: none;

  @media ${device.tabletLandscapeDown} {
    display: block;
  }
`;

const Menu = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  height: ${props => (props.open ? '100%' : '0')};
  width: 100vw;
  display: flex;
  flex-direction: column;
  background: #fff;
  color: #435172; 
  //transition: height 0.3s ease;
  z-index: 2;
  overflow: hidden;
  justify-content: center;
  text-align: start;
  
  a {
    color: #435172 !important;
    font-size: 24px;
    font-weight: 600;
  }
`;

const ContactBlock = styled.div`
  margin: 0 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 20px;
  margin: 20px;
`;

const StyledLink = styled.a`
  && {
   margin: 10px;
    color: #83c7ff !important;
    font-size: 18px;
    font-weight: 600;
    text-decoration: none;
  }
`;

const BlueButton = styled(Link)`
  && {
    color: #ffffff !important;
    display: inline-block;
    border-radius: 27.5px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
    background-color: #435172;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.33;
    color: #ffffff;
    text-decoration: none;
    margin-left: 0.5em;
    padding: 12px 38px;
    margin: 10px;
  }
`;

const MenuComponent = ({ color }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <Container>
      <MenuButton color={color} open={menuOpen} onClick={() => setMenuOpen(!menuOpen)}/>
      <Menu open={menuOpen}>
        <MenuItem to="/">Home</MenuItem>
        <MenuItem to="/about">About us</MenuItem>
        <MenuItem to="/case-studies">Case studies</MenuItem>
        <MenuItem to="/services">Services</MenuItem>
        <MenuItem to="/contact">Hire us</MenuItem>
        <ContactBlock>
          <StyledLink href="mailto:hello@361studio.co">hello@361studio.co</StyledLink>
          <BlueButton to="/contact">Get in touch!</BlueButton>
        </ContactBlock>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <OtherSites/>
        </div>
      </Menu>
    </Container>
  );
};

export default MenuComponent;
