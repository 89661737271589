import React from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as CloseMenu } from './images/menu-open.svg';
import { ReactComponent as OpenMenu } from './images/menu-closed.svg';
import { ReactComponent as OpenMenuDark } from './images/menu-closed-dark.svg';

const Container = styled.div`
  position: ${props => (props.open ? 'fixed' : 'relative')};
  top: ${props => (props.open ? '30px' : '-10px')};
  right: ${props => (props.open ? '10px' : '-14px')};

  height: 32px;
  width: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 4px;
  z-index: 3;
`;

const MenuButton = ({ open, color, onClick }) => {
  var Icon;

  if (open) {
    Icon = CloseMenu;
  } else {
    if (color === '#fff') {
      Icon = OpenMenu;
    } else {
      Icon = OpenMenuDark;
    }
  }

  return (
    <Container onClick={onClick} open={open}>
      <Icon/>
    </Container>
  );
};

export default MenuButton;
