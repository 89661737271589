import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

const Container = styled.div`
  opacity: 0;
  animation: 1s appear forwards;
  animation-delay: ${props => props.delay};

  @keyframes appear {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes slideIn {
    0% {
      transform: translateX(-2%);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes shrink {
    0% {
      width: 95%;
    }
    100% {
      width: 90%;
    }
  }
`;

const MenuItem = styled(Link)`
  font-size: 1.2rem;
  padding: 1rem 0;
  margin: 0 5%;
  cursor: pointer;
  color: ${props => (props.hover ? '#fff' : '#fafafa')} !important;
  transition: color 0.2s ease-in-out;
  animation: 0.5s slideIn forwards;
  animation-delay: ${props => props.delay};
  text-decoration: none;
  display: inline-block;
`;

const MenuItemComponent = ({ children, delay, to }) => {
  const [hover, setHover] = useState(false);

  return (
    <Container delay={delay}>
      <MenuItem
        hover={hover.toString()}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        to={to}
      >
        {children}
      </MenuItem>
    </Container>
  );
};

export default MenuItemComponent;
