import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import data from './sites';

const Container = styled.div`
  padding-bottom: 10px;
  white-space: nowrap;

  a {
    margin-right: 21px;
  }

  a:last-child {
    margin-right: 0;
  }
`;

const OtherSites = props => (
  <Container>
    {data.map(site => (
      <a
        key={site.name}
        href={site.url}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={require(`${site[props.size].image}`)} alt={site.name} />
      </a>
    ))}
  </Container>
);

OtherSites.propTypes = {
  size: PropTypes.oneOf(['small', 'medium']),
};

OtherSites.defaultProps = {
  size: 'small',
};

export default OtherSites;
