import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

const Row = styled.div`
  margin: 0 auto;
  max-width: 1440px;
`;

const RowComponent = ({ children }) => <Row>{children}</Row>;

RowComponent.propTypes = {
  children: PropTypes.any,
};

RowComponent.defaultProps = {};

export default RowComponent;
