import React from 'react';
import PropTypes from 'prop-types';
import SEO from '../../components/SEO';
import PageHeader from '../../components/PageHeader';
import PageFooter from '../PageFooter';

const PageLayout = ({
  title,
  header,
  intro,
  children,
  ...otherProps
}) => (
  <>
    <SEO title={title} />
    <PageHeader
      title={header}
      subtitle={intro}
      {...otherProps}
    />
    <main>{children}</main>
    <PageFooter />
  </>
);

PageLayout.propTypes = {
  title: PropTypes.string,
  header: PropTypes.string,
  intro: PropTypes.string,
  visual: PropTypes.node,
  additionalContent: PropTypes.any,
  children: PropTypes.node.isRequired,
};

export default PageLayout;
